let state = {

    header: {
        property_name: '',
        brand: {
            name: '',
            logo: ''
        },
        property_logo: {
            property_initial: '',
            property_image: '',
            is_initial: false
        },
        booking_source: '',
        booking_source_logo: {
            booking_source_initial: '',
            booking_source_image: '',
            is_initial: false
        },
        external_link: '',
    },

    meta: {
        brand:{
            name: '',
            logo: '',
            initials: ''
        },
        current_step: -1,
        to_be_completed_steps: 0,
        routes: [

            {
                title: '',
                count: 0,
                default_step_name: "",
            }
        ],
        is_credit_card_scan_required: false,
        is_passport_scan_required: false,
        next_link: '',
        is_completed: false,
        is_previous_step:false,
        pre_checkin_completed:false,
        standard_check_in_time:'',
        identity_name: '',
        identity_type: '',
        acceptable_document_types: {},
        document_upload_instructions: null,
        is_whitelabel_solution_enabled : false
    },

    step_0: {
        guest_name: '',
        property: {
            name: '',
            logo: '/storage/uploads/property_logos/no_image.png'
        },
        booking_source: {
            name: 'Expedia',
            logo: '/storage/uploads/booking_souce_logo/expedia-logo.png'
        },
        amount: '',
        display_amount: '',
        arrival_time: '',
        adults: '',
        children: '',
        checkin_date: '--',
        checkout_date: '--',
        check_in_time: '--',
        check_out_time: '--',
        reference: '',
        pre_step: '',
        next_step: '',
        next_link: '',
        is_completed: false
    },

    payment_summary:{
        upsell_amount_due: 0,
        payments: [],
        card: {},
        property_status : 1
    },

    step_1: {
        email: '',
        phone: '',
        guests: 0,
        adults: 0,
        childern: 0,
        status: false,
        error_message: {
            email: '',
            phone: '',
            adults: ''
        },
        error_status: {
            email: false,
            phone: false,
            adults: false
        },
        section_detail_error_messages : {},
        fetched: false,
        is_active_basic_info_email: false,
        is_active_basic_info_phone: false,
        is_active_basic_info_guest: false,
    },

    step_2: {
        arriving_by: '',
        plane_number: '',
        other: '',
        arrival_time: '',
        standard_check_in_time:null,
        status: false,
        error_message: {
            arriving_by: '',
            plane_number: '',
            arrival_time: '',
            other: ''
        },
        error_status: {
            arriving_by: false,
            plane_number: false,
            arrival_time: false,
            other: false
        },
    },

    step_3: {
        images: [],
        next_link: '',
        is_completed: false,
    },

    guest_images_status: {},
    main_guest_id: null,

    add_on_service: {
        data: {
            purchased: [],
            available: []
        },
        in_cart_due_amount: 0,
    },
    credit_card_step: {

        _3ds_authenticate: {
            open_modal_box: false,
            iframe_url: ''
        },
        upsells: [],
        upsell_total: 0,
        upsell_paid: 0,
        upsell_amount_due: 0,
        upsell_amount_to_authorize: 0,
        symbol: '$',
        payments:[],
        card: {
            card_type: '',
            cc_last_digit: '',
            card_available: false,
            expiry: '',
            sd_auth_present: false,
            sd_msg: '',
            need_to_update_card:true,
            card_image: '',
        },
        new_card: {
            name: '',
            number: '',
            expiry: '',
            cvv: '',
            pgTerminal: {
                    cc_form_name: 'dummy-add-card', //'dummy-add-card',
                    is_token: false,
                    is_redirect: false,
                    redirect_link: '',
                    public_key: '',
                    client_secret: '',
                    account_id: '',
                    first_name: '',
                    last_name: '',
                    booking_id: '',
                    with3DsAuthentication: true,
                    show_authentication_button: false,
                    attempt_charge: false
                }
        },
        error_message: {
            name: '',
            number: '',
            expiry: '',
            cvv: ''
        },
        error_status: {
            name: false,
            number: false,
            expiry: false,
            cvv: false
        },
        next_link: '',
        is_completed: false,
    },

    summary: {
        reference: '',
        confirmation_code:'',
        check_in: '',
        amount: '',
        display_amount: '',
        check_out: '',
        cc_last_4_digit: '',
        arrival_time: '',
        arriving_by: '',
        flight_no: '',
        guest_images: [],
        full_name: '',
        email: '',
        phone: '',
        adults: '',
        childern: '',
        questions: [],
        links: {
            step_1: '',
            step_2: '',
            step_3: '',
            step_4: '',
            step_questionnaire: ''
        },
        arrival_info: true,
        verification: true,
        card_info: true,
        contact_info: true,
        questionnaire_available: true,
        is_any_question_editable: false,
        signature_pad: false,
        signature_type: '',
        terms_link:'#',
        sections: [],
        is_active_basic_info_guest: 0

    },

    tac:{
        has_required_tac:false,
        is_accepted_tac:false,
    },
    step_7: {
        selfie: ''
    },

    /** Questionnaire Step **/
    questionnaire: {
        title: '',
        description: '',
        status: true,
        already_answered:false,
        questions: [],
    },
    edit_question : false,

    /** END | Questionnaire Step **/

    additional_guests : {
        total_guests : 1,
        available_guest : 1,
        guests: [],
        guest_who_completed_information: 0,
        display_all_guests: false,
    },

    additional_guest_detail : {
        guest_images: [],
        acceptable_document_types: {},
        document_upload_instructions: null,
    },
    additional_guest_detail_errors : {},
    add_new_guest : {
        name: '',
        email: '',
        guest_type: '1',
    },
    add_new_guest_errors: {},

    step_position: {
        0: 'step_0',
        1: 'step_1',
        8: 'step_8',
        2: 'step_2',
        3: 'step_3',
        4: 'add_on_service',
        5: 'credit_card_step',
        6: 'step_7',
        7: 'summary',
    },
};

export default state;
