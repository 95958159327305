import {ccRiskScore} from './constants';

let general_root_state = {

    guest_upload_doc_id: 0,
    guest_credit_card_id: 0,
    info_of_credit_card: {},
    booking_id_action_chat: {
        data: []
    },
    reduce_amount_data: {
        booking_id: '',
        tran_id: '',
        current_amount: 0,
        current_amount_without_format: 0,
        new_amount: false,
        new_balance: false
    },

    change_amount_data: {
        booking_id: '',
        tran_id: '',
        current_amount: 0,
        current_amount_without_format: 0,
        new_amount: false,
        new_balance: false
    },

    change_sd_amount_data: {
        booking_id: '',
        cc_auth_id: '',
        current_amount: 0,
        current_amount_without_format: 0,
        new_amount: false,
    },
    change_due_date_data: {
        booking_id: '',
        transaction_id: '',
        due_date: '',
        check_in_date: '',
        check_out_date: '',
        check_in_time: '',
        check_out_time: '',
        current_date: '',
        is_auth: 1,
    },
    additional_charge_active_booking_id: 0,
    addNewCardOnly: false,
    refund_amount_active_booking_id: 0,
    disable_partial_refund: false,
    disable_partial_capture_and_release: false,
    refund_amount_active_transaction_id: 0,
    refund_amount_active_payment_request_id:0,
    refund_amount_active_reusable_payment_request_id:0,
    amount_valid_to_refund: 0,
    upsell_amount_valid_to_refund: 0,
    upsell_refund_active_order_detail_id:0,
    upsell_refund_active_booking_id: 0,
    capture_amount_active_booking_id: 0,
    capture_amount_active_cc_auth_id: 0,
    capture_amount_active_payment_request_id:0,
    capture_amount_active_reusable_payment_request_id:0,
    capture_amount_active_modal_title: '',
    amount_valid_to_capture: 0,
    payment_request_active_id: 0,
    payment_request_active_action_type: 'new',
    payment_request_active_email: null,
    payment_request_active_currency_id: null,
    payment_request_active_amt: null,
    capture_amount: {
        error_message: {
            amount: '',
            description: ''
        },
        error_status: {
            amount: false,
            description: false
        },
    },
    images: {},
    meta: [],

    document_upload: {
        error_message: {
            credit_card: '',
            passport: '',
            selfie: '',
        },
        error_status: {
            credit_card: false,
            passport: false,
            selfie: false
        },
    },

    document_description: {},
    guests: [],
    documents: {
        documents_to_check: [],
        accepted_documents: [],
        rejected_documents: [],
        deleted_documents:[],
    },

    pms_prefix: 'general',

    company_timezone: 'UTC',
    is_company_timezone_set:false,

    page_status: 'Please wait while your transaction is processing...',
    _3ds: {
        open_modal_box: false,
        iframe_url: '',
        iframe_urls: [],
        access_token: undefined,
        additional_data: undefined, // Used in cybersource, data sent in this field will be received back after redirect of 3ds.
        gateway_name: undefined,
        fakeTransactionId: false,
    },
    // Add for CyberSource
    deviceFingerPrinting: {
        status: undefined,
        orderId: undefined,
        accessToken: undefined,
        referenceId: undefined,
        deviceDataCollectionUrl: undefined,
        customer: undefined,
        hash: undefined,
        miscellaneous: undefined,
        loadDeviceFingerprintingIframe: false, // To Load Iframe Code
        pgId: 0,
        added_card: undefined, // This field will hold card id that is added on runtime and used rather than the selected card from the previously added cards.
        paymentRequest: {},
        paymentBooking: {},
    },

    pg_terminal: {
        cc_form_name: '',
        is_token: false,
        is_redirect: false,
        redirect_link: '',
        public_key: '',
        client_secret: '',
        account_id: '',
        first_name: '',
        last_name: '',
        booking_id: '',
        with3DsAuthentication: true,
        show_authentication_button: false,
        attempt_charge: false,
        src:'',
        terminal:'',
        card_terminal_additional_fields:[],
        additional_fields_errors:[],
        user_pg_id:null,
        status: false,
        status_code: 0,
        message: '',
        meta: {},
        makeCardVC: false,
        caller:'',
        concurrency: false,
    },

    all_booking_sources:[], //ids, names
    all_countries:[], //ids, names
    attached_booking_sources: [{"name": "All Booking Source", "id": 0}],
    all_booking_types: [{"name": "All Booking Payment Types", "code": 0}],
    attached_booking_types: [],
    ask_for_import_can_processable:false,
    can_sync_booking:false,
    is_syncing_booking: false,
    is_property_syncing: false,
    refresh_booking_sources: false,
    booking_cards: [],
    is_booking_email_available: true,
    is_chargeback_protection_supported: false,
    is_sms_plan_subscribed:false,
    property_upsell_guide:[],
    guest_portal_terminal_active_tab: '', //to handle Card terminal component for Add-ons and Payment Details.
    generate_unique_token: {token: ''},

    // Credit Card Risk Score 
    ccRiskScore: ccRiskScore,
};

export default general_root_state;
